class ResourceItems {
  constructor () {
    this.selector = '.card-item'
  }

  init () {
    document.querySelectorAll(this.selector).forEach(this.distribute.bind(this))
  }

  distribute ($elem) {
    var childElement = $elem.querySelector('.resource-specific-class')
    var divHeight = childElement.children[0].offsetHeight
    var lineHeight = 20
    var lines = parseInt(divHeight / lineHeight - 1)

    if (lines >= 3) {
      childElement.classList.add('text-title')

      const classHandler = (val1, val2) => {
        childElement.classList[val1]('text-title')
        childElement.classList[val2]('!h-auto')
      }

      $elem.addEventListener('mouseenter', classHandler('remove', 'add'))
      $elem.addEventListener('mouseleave', classHandler('add', 'remove'))
    }
  }

  clickResource (id) {
    document.querySelector(`[data-resource-item='${id}']`).children[0].click()
  }
}

export default ResourceItems
