import autosize from 'autosize'

class TextArea {
  constructor () {
    this.selector = 'textarea[data-autosize]'
  }

  init () {
    autosize(document.querySelectorAll(this.selector))

    document.addEventListener('overlay:opened', () => {
      const { $panel } = window.app.modal

      autosize($panel.querySelectorAll(this.selector))
    })
  }

  autosizeElement (element) {
    autosize(element)
  }

  autosizeUpdate (element) {
    autosize.update(element)
  }
}

export default TextArea
