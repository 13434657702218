/** * Activity Stream Management - A class to assist with javascript sprinkles
*/
class ActivityStreamManagement {
  stream (render, streamElement) {
    const newMessageAlert = document.querySelector('#new-message-alert')
    const noteContainer = document.querySelector('#note-container')

    if (!noteContainer) { return }

    render(streamElement)

    this.watchForNewActivities()
    this.newMessageText()
    this.handleMessageClick(newMessageAlert)

    window.app.tooltipManager.init()
    window.app.tooltipManager.resetTooltip()
  }

  watchForNewActivities () {
    const activities = Array.from(document.querySelectorAll('.broadcasted'))
    const newMessageAlert = document.querySelector('#new-message-alert')
    const options = {
      root: document.querySelector('#activities-container'),
      threshold: 0.10,
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const activitiesNotification = document.querySelector('.activities-notification')
        const activitiesCount = activitiesNotification.querySelector('.activities-count')
        const messageContent = entry.target.querySelector('.message-content')
        const messageCountText = document.querySelector('.btn-count')
        const newMessageCount = activities.length.toString()
        const container = entry.target.closest('li')

        if (entry.isIntersecting) {
          container.classList.remove('broadcasted')
          messageContent.classList.add('transition', 'duration-500', 'ease-in')
          messageContent.classList.remove('bg-whitelabel-100')

          const updatedActivities = Array.from(document.querySelectorAll('.broadcasted'))

          activitiesCount.innerHTML = `${updatedActivities.length.toString()}`
          updatedActivities.length < 2 ? messageCountText.innerHTML = `${updatedActivities.length.toString()} New Message` : messageCountText.innerHTML = `${updatedActivities.length.toString()} New Messages`

          if (updatedActivities.length < 1) {
            newMessageAlert.classList.add('hidden')
            activitiesNotification.classList.add('hidden')
          }

          observer.unobserve(entry.target)
        } else {
          newMessageAlert.classList.remove('hidden')
          activitiesNotification.classList.remove('hidden')
          activitiesCount.innerHTML = `${newMessageCount}`
          newMessageCount < 2 ? messageCountText.innerHTML = `${newMessageCount} New Message` : messageCountText.innerHTML = `${newMessageCount} New Messages`
        }
      })
    }, options)

    return activities.forEach(activity => {
      observer.observe(activity)
    })
  }

  newMessageText (val = 'add') {
    const newMessages = document.querySelectorAll('.broadcasted').length.toString()
    const messageCount = document.querySelector('.message-count')
    const activitiesNotification = document.querySelector('.activities-notification')

    if (!activitiesNotification) { return }

    const activitiesCount = activitiesNotification.querySelector('.activities-count')

    if (!messageCount || !newMessages) { return }

    activitiesNotification.classList.remove('hidden')
    activitiesCount.innerHTML = `${newMessages}`

    newMessages < 2 ? messageCount.innerHTML = `${newMessages} New Message` : messageCount.innerHTML = `${newMessages} New Messages`
  }

  handleMessageClick (message) {
    if (!message) { return }

    message.addEventListener('click', () => {
      const broadcastedArr = Array.from(document.querySelectorAll('.broadcasted'))

      broadcastedArr[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  }
}

export default ActivityStreamManagement
