/* eslint-disable new-cap */
/* eslint-disable no-new */
class ComboBox {
  constructor () {
    this.selector = '[data-combo-box]'
  }

  init () {
    const bindModalSelects = this.initModalSelects.bind(this)

    document.addEventListener('turbolinks:load', bindModalSelects)
    document.addEventListener('overlay:opened', bindModalSelects)
    document.addEventListener('overlay:populated', bindModalSelects)
    window.addEventListener('resize', bindModalSelects)
    document.addEventListener('turbolinks:load', this.initContainerSelects.bind(this))
  }

  // Initialize a **single** select
  initSelect (elem) {
    if (elem.disabled) { return }
    if (Array.from(elem.classList).includes('tomselected')) { return }

    // build the settings for each different combo box case.
    const settings = new window.app.buildSettings().run(elem)

    const ts = new window.app.tomSelect(elem, settings)

    if (elem.name === 'select_account' || elem.name === 'select_compliance_form') {
      ts.wrapper.classList.add('px-0')
      ts.control.classList.add('account-select', 'dropdown-type-input')
    }

    // Custom select events for using '$(watch)'-like operations
    // Currenty this if only for the resource item form -
    if (elem.dataset.onSelectEvent) {
      elem.tomselect.control.classList.add('dropdown-type-input')
      elem.tomselect.on('item_add', function (value, item) {
        new window.app.contentType(item).init()
      })
    }
  }

  // Initialize **multiple** selects
  initSelects (container = document) {
    const $allSelects = container.querySelectorAll(this.selector)

    // Wrap this in a `requestAnimationFrame` to ensure that Select2
    // calculates the correct width values. This is necessary
    // because of how we animate the modal window into view.
    window.requestAnimationFrame(() => {
      $allSelects.forEach(this.initSelect)
    })
  }

  initContainerSelects () {
    this.initSelects(document.target)
  }

  // Initialize selects within the modal window
  initModalSelects () {
    this.initSelects(window.app.modal.$panel)
    this.initSelects(window.app.drawer.$panel)
  }
}

export default ComboBox
