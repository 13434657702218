class PageContent {
  constructor () {
    this.selectors = {
      root: '[data-page-content]',
      section: '[data-page-content-section]',
    }

    this.$root = null
    this.$sections = null
  }

  init () {
    this.$root = document.querySelector(this.selectors.root)
    if (!this.$root) return

    this.$sections = this.$root.querySelectorAll(this.selectors.section)
    if (!this.$sections.length) return

    document.addEventListener('click', this.handleAnchorClick.bind(this))
    this.$root.addEventListener('scroll', this.handleRootScroll.bind(this))
  }

  deinit () {
    if (!this.$root || !this.$sections.length) return

    document.removeEventListener('click', this.handleAnchorClick)
    this.$root.removeEventListener('scroll', this.handleRootScroll)
  }

  determineCurrentSection (scrollTop) {
    let navBar = document.querySelector('#content-nav').offsetHeight
    let navBarOffset = navBar / 1.5
    let currentSection = null

    Array.from(this.$sections).some($section => {
      const { offsetHeight, offsetTop } = $section
      const offsetBottom = offsetTop + offsetHeight

      if (scrollTop > (offsetTop - (navBar + navBarOffset)) && scrollTop < (offsetBottom - navBarOffset)) {
        currentSection = $section
        return true
      }
    })

    return currentSection
  }

  handleAnchorClick (event) {
    if (!('pageContentAnchor' in event.target.dataset)) return

    this.showSectionFor(event.target)
    event.preventDefault()
  }

  handleRootScroll (event) {
    const { scrollTop } = event.target
    const newSection = this.determineCurrentSection(scrollTop)

    if (newSection === null || newSection === this.currentSection) return

    window.dispatchEvent(new CustomEvent('page-content:mark-section', {
      detail: {
        section: newSection,
      },
    }))
  }

  showSectionFor ($target) {
    const navBar = document.querySelector('#content-nav').offsetHeight
    const $section = document.querySelector($target.getAttribute('href'))

    // Bail if no section was found
    if (!$section) return

    // Finally ,scroll the content window
    this.$root.scroll({
      top: $section.offsetTop - navBar,
      behavior: 'smooth',
    })
  }
}

export default PageContent
