/** * Attachment Stream Management - A class to assist with javascript sprinkles
*/
class AttachmentStreamManagement {
  stream (render, streamElement) {
    const attachmentContainer = document.querySelector('.multi-select-attachment-container')
    const attachmentsEmptyState = document.querySelector('.attachments-empty-state')

    attachmentsEmptyState.classList.add('hidden')

    this.blankStatePositioning('remove')

    render(streamElement)

    this.checkForExistingAttachments(attachmentContainer)
    this.removeBorderStyle()
    this.getNewAttachments()
    this.revealDownloadAllButton()

    if (!attachmentContainer) return

    // eslint-disable-next-line new-cap
    new window.app.multiAttachment(attachmentContainer).init()
  }

  checkForExistingAttachments (el) {
    const containers = Array.from(document.querySelectorAll('.attachments-container'))

    containers.forEach((container) => {
      const attachments = Array.from(container.querySelectorAll('.file-container'))
      const actionBar = container.parentElement.querySelector('#action-bar')

      if (attachments.length > 0) {
        container.classList.remove('hidden')
        actionBar.classList.remove('hidden')
      } else {
        container.classList.add('hidden')
        actionBar.classList.add('hidden')
      }
    })
  }

  blankStatePositioning (val) {
    const attachmentsBin = document.querySelector('.attachments-bin')
    const classes = ['h-full', 'flex', 'items-center', 'justify-center']

    if (!attachmentsBin) return

    attachmentsBin.classList[val](...classes)
  }

  emptyStateHandler () {
    const attachmentsEmptyState = document.querySelector('.attachments-empty-state')
    const attachments = Array.from(document.querySelectorAll('.file-container'))

    if (attachments.length < 1) attachmentsEmptyState.classList.add('hidden')

    attachmentsEmptyState.classList.remove('hidden')
    attachmentsEmptyState.classList.add('animate-empty-state')
  }

  removeBorderStyle () {
    const newAttachments = Array.from(document.querySelectorAll('.broadcasted-attachment'))

    for (let i = 0; i < newAttachments.length - 1; i++) {
      newAttachments[i].classList.remove('rounded-b')
    }
  }

  getNewAttachments () {
    const newAttachments = Array.from(document.querySelectorAll('.broadcasted-attachment'))

    newAttachments.forEach((attachment) => {
      attachment.classList.add('bg-whitelabel-100')

      window.app.util.onVisible(attachment, () => { this.newAttachmentInView(attachment) }, 1)
    })
  }

  newAttachmentInView (attachment) {
    attachment.classList.remove('broadcasted-attachment')
    attachment.classList.add('transition', 'duration-500', 'ease-in')
    attachment.classList.remove('bg-whitelabel-100')
  }

  revealDownloadAllButton () {
    const downloadAllButton = document.querySelector('#download-all')

    if (!downloadAllButton) return

    downloadAllButton.classList.remove('hidden')
  }
}

export default AttachmentStreamManagement
