import { Controller } from '@hotwired/stimulus'
import FocusTrapController from './focus-trap'

/** @extends {Controller<HTMLElement>} */
export default class BulkEditController extends Controller {
  static outlets = ['focus-trap']

  static targets = [
    'bulkActions',  // (Optional) A container for actions that can
                    // be performed on the checked items
    'bulkCheckbox', // The checkbox that toggles all individual checkbox items
    'checkbox',     // An individual checkbox
  ]

  static values = {
    checked: Boolean,
    indeterminate: Boolean,
  }

  declare checkedValue: Boolean
  declare indeterminateValue: Boolean
  declare readonly bulkActionsTarget: HTMLElement
  declare readonly bulkCheckboxTarget: HTMLInputElement
  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly focusTrapOutlet: FocusTrapController | null
  declare readonly hasBulkActionsTarget: Boolean

  connect() {
    this.#handleCheckboxChange()
  }

  bulkCheckboxTargetConnected(elem: HTMLInputElement) {
    elem.addEventListener('change', this.#handleBulkCheckboxChange.bind(this))

    if (elem.form) {
      elem.form.addEventListener('reset', this.#handleCheckboxChange.bind(this))
    }
  }

  bulkCheckboxTargetDisconnected(elem: HTMLInputElement) {
    elem.removeEventListener('change', this.#handleBulkCheckboxChange.bind(this))

    if (elem.form) {
      elem.form.removeEventListener('reset', this.#handleCheckboxChange.bind(this))
    }
  }

  checkboxTargetConnected(elem: HTMLInputElement) {
    elem.addEventListener('change', this.#handleCheckboxChange.bind(this))
  }

  checkboxTargetDisconnected(elem: HTMLInputElement) {
    elem.removeEventListener('change', this.#handleCheckboxChange.bind(this))
  }

  // Private methods

  #handleBulkCheckboxChange(event: Event) {
    const { checked } = (event.target as HTMLInputElement)
    this.checkboxTargets.forEach(checkbox => checkbox.checked = checked)
    this.#setValues()
    this.#setBulkActionVisibility()
    this.#handleFocusTrap(checked)
  }

  #handleCheckboxChange() {
    // NOTE: an immediately fired setTimeout is used to ensure that the
    // checkboxes are in the state that we expect them to be in.
    // This is only really needed in the case of the form `reset` event
    // (which may not even be present depending on where this bulk edit is used)
    setTimeout(() => {
      this.#setValues({ updateBulkCheckbox: true })
      this.#setBulkActionVisibility()
    }, 0)
  }

  #handleFocusTrap(checked: boolean) {
    if (!this.focusTrapOutlet) return

    if (checked) {
      this.focusTrapOutlet.activate()
    } else {
      this.focusTrapOutlet.deactivate()
    }
  }

  #setBulkActionVisibility() {
    if (!this.hasBulkActionsTarget) return

    if (this.checkedValue) {
      this.bulkActionsTarget.classList.remove('hidden')
    } else {
      this.bulkActionsTarget.classList.add('hidden')
    }
  }

  #setValues({ updateBulkCheckbox = false } = {}) {
    const checkedBoxes = this.checkboxTargets.filter(elem => elem.checked)
    const checked = checkedBoxes.length > 0
    const indeterminate = checked && checkedBoxes.length < this.checkboxTargets.length

    this.checkedValue = checked
    this.indeterminateValue = indeterminate

    if (updateBulkCheckbox) {
      this.bulkCheckboxTarget.checked = checked
      this.bulkCheckboxTarget.indeterminate = indeterminate
    }
  }
}
