import { stimulus } from './application'
import BulkDetailsController from './controllers/bulk-details'
import BulkEditController from './controllers/bulk-edit'
import ColorPaletteController from './controllers/color-palette'
import ColorPickerController from './controllers/color-picker'
import DispatchController from './controllers/dispatch'
import DropdownController from './controllers/dropdown'
import FetchController from './controllers/fetch'
import FilterViewController from './controllers/filter-view'
import FocusTrapController from './controllers/focus-trap'
import FormChangeController from './controllers/form-change'
import FxController from './controllers/fx'
import IdentitySwitcherController from './controllers/identity-switcher'
import PageNavOutlineController from './controllers/page-nav-outline'
import ScrollbarController from './controllers/scrollbar'
import SnackBarController from './controllers/snack-bar'
import TabGroupController from './controllers/tab-group'
import TimestampController from './controllers/timestamp'
import ToggleController from './controllers/toggle'
import TooltipController from './controllers/tooltip'

stimulus.register('bulk-details', BulkDetailsController)
stimulus.register('bulk-edit', BulkEditController)
stimulus.register('color-palette', ColorPaletteController)
stimulus.register('color-picker', ColorPickerController)
stimulus.register('dispatch', DispatchController)
stimulus.register('dropdown', DropdownController)
stimulus.register('fetch', FetchController)
stimulus.register('filter-view', FilterViewController)
stimulus.register('focus-trap', FocusTrapController)
stimulus.register('form-change', FormChangeController)
stimulus.register('fx', FxController)
stimulus.register('identity-switcher', IdentitySwitcherController)
stimulus.register('page-nav-outline', PageNavOutlineController)
stimulus.register('scrollbar', ScrollbarController)
stimulus.register('snack-bar', SnackBarController)
stimulus.register('tab-group', TabGroupController)
stimulus.register('timestamp', TimestampController)
stimulus.register('toggle', ToggleController)
stimulus.register('tooltip', TooltipController)
