class ColorCode {
  constructor (clipboard) {
    this.clipboard = clipboard
  }

  handleButtons (buttons) {
    Array.from(buttons).forEach($button => {
      $button.classList.add('disable-field')
    })
  }

  enableCopy () {
    this.clipboard.on('success', (event) => {
      event.clearSelection()

      var colorButtons = document.querySelectorAll('.color-code-buttons')
      this.handleButtons(colorButtons)

      var svgContainer = event.trigger.children[2]
      var colorCodeButton = event.trigger.parentElement.querySelector('.color-code-buttons')
      var container = event.trigger.parentElement.parentElement

      svgContainer.classList.remove('hidden')
      colorCodeButton.classList.add('justify-between')
      container.classList.add('bg-accent-green', 'text-white', 'justify-between')
      container.classList.remove('group-hover:bg-gray-50')

      var original = event.trigger.innerHTML
      event.trigger.innerHTML = `<div>Copied!</div><div>${svgContainer.innerHTML}</div>`

      window.setTimeout(function () {
        Array.from(colorButtons).forEach($button => {
          $button.classList.remove('disable-field')
        })

        colorCodeButton.classList.remove('justify-between')
        container.classList.remove('bg-accent-green', 'text-white')
        container.classList.add('group-hover:bg-gray-50')

        event.trigger.innerHTML = original
        event.trigger.children[2].classList.add('hidden')
      }, 1000, event)
    })
  }
}

export default ColorCode
